<template>
  <nav class="pl-0">  
    <ul class="flex flex-wrap bg-slate-500">
      <li class="sm:pl-12 md:pl-8 p-6 hover:bg-slate-600">
        <router-link :to="{ path: '/' }" class="text-slate-100 text-2xl">Home</router-link>
      </li>
      <li class="p-6 hover:bg-slate-600">
        <router-link :to="{ path: '/prints' }" class="text-slate-100 text-2xl">Prints in Focus</router-link>
      </li>
      <li class="p-6 hover:bg-slate-600">
        <a href="https://www.imagesinearlymodernbritain.co.uk" class="text-slate-100 text-2xl" target="_blank">Blog</a>
      </li>
    </ul>
  </nav>  
</template>