<template>
    <div class="justify-center relative container mx-auto px-40 bg-slate-300 flex flex-wrap border-2 ">
      <HomeLink />
      <ReturnToSearchButton />
        <div class="flex h-full dark:bg-gray-800 bg-stone-100 p-8 flex-col">
          <div class="flex-none w-96 text-left relative p-2  border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" v-for="item in firstHalf" :key="item.id">
              <span>
                <img class="pr-2 pb-2" :src="imagePath + '/'+ item.jpgNum" width="100">
              </span>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm pt-2">
                  BPI number : {{  item.id }} 
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm">
                  Title : {{ item.title }} 
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm">
                  Associated name : {{ item.assocName }} 
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300  text-left text-sm">
                  Producer : {{ item.producer }}
              </p>

              <button type="button" aria-label="image modal" class="bg-slate-500 pl-2 pt-2 rounded w-32 absolute top-5 left-40 hover:bg-slate-450" @click="activateImagesModel(item.id)" @keydown.enter="activateImagesModel(item.id)">    
                <a href="#" class="mb-2 text-white hover:bg-slate-450 inline-flex items-center font-medium">Learn More
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                  </a>
                </button>
          </div>
        </div>
    
        <div class="flex h-full dark:bg-gray-800 bg-stone-100 p-8 flex-col">

          <div class="flex-none w-96 md:min-w-0 text-left relative p-2 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" v-for="item in secondHalf" :key="item.id">
              <span>
                <img class="pr-2 pb-2" :src="imagePath + '/'+ item.jpgNum" width="100">
              </span>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm pt-2">
                  BPI number : {{  item.id }}
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm">
                  Title : {{ item.title }} 
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300 text-left text-sm">
                  Associated name : {{ item.assocName }} 
              </p>
              <p class="leading-relaxed text-base text-black dark:text-gray-300  text-left text-sm">
                  Producer : {{ item.producer }}
              </p>

              <button type="button"  aria-label="image modal" class="bg-slate-500 pl-2 pt-2 rounded w-32 absolute top-5 left-40 hover:bg-slate-450" @click="activateImagesModel(item.id)" @keydown.enter="activateImagesModel(item.id)">
                <a href="#" class="mb-2 text-white inline-flex items-center font-medium">Learn More
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                  </a>
                </button>
            </div>    
        </div>
        <PaginationPage :total-pages="totalPages" :per-page="20" :current-page="currentPage" @page-changed="onPageChange"> </PaginationPage> 
      </div>
     <ImagesModal v-if="showImagesModal" @close="closeImagesModal()" :bpinumber="bpiNumber" /> 
  </template>
    
    <script>

    import HomeLink from '../components/HomeLink.vue'
    import ReturnToSearchButton from '../components/ReturnToSearchButton.vue'
    import ImagesModal from '../components/ImagesModal.vue'
    import PaginationPage from '@/components/PaginationPage.vue'
    import { useBPIStore } from '@/store/BPIs'
    import { useImageDataStore } from '@/store/imagedata'

    export default {
      data() {
          return {
              imagePath : 'https://britishprintedimages.blob.core.windows.net/input-images/thumbnails',
              firstHalf : [],
              secondHalf : [],
              imageData : [],
              bpiNumber : '',
              totalPages : 0,
              currentPage : 0,
              showImagesModal : false
          };
      },
      components: { 
        HomeLink,
        ReturnToSearchButton,
        ImagesModal,
        PaginationPage
      },
      methods : {
        getImages(){
          const bpiStore = useBPIStore();
          const imageDataStore = useImageDataStore();
          let bpiList = [];
          this.imageData = [];
        
          // get bpi listing , these are already in pinia storage
          bpiList = bpiStore.getBPIList();

          if(bpiList.length !== 0){
            bpiList = bpiList.split(',');
          }

          const bpi_count = bpiList.length;
          console.log('number of results is ' + bpi_count);
          const bpi_index = bpiStore.BPI_index;

          console.log('bpi_index is ' + bpi_index);
          let count = bpi_index + bpi_count;
      
          // loop through the array of items, getting each object in turn
          for (let i = bpi_index; i < count; i++){

            console.log('index is ' + i);

            let imageDetails = imageDataStore.getImageDetails[i];
            localStorage.setItem(imageDetails.imageData.id, JSON.stringify(imageDetails.imageData));
            // push the imagedata object onto the class variable this.imageData
            this.imageData.push(imageDetails.imageData);
          }  
          
          const half = Math.ceil(this.imageData.length / 2); 
          this.firstHalf = this.imageData.slice(0, half);
          this.secondHalf = this.imageData.slice(half); 

          console.log(this.firstHalf);
          console.log(this.secondHalf);
        },
        async onPageChange(page, status) {
          this.currentPage = page;
          console.log('onPageChange page count is ' + this.currentPage);
          const bpiStore = useBPIStore(); 
          let bpiList = [];

          console.log('status is ' + status);
          if(status === 'forward'){
            this.currentPage++; 
            console.log('onPageChange incremented page count is ' + this.currentPage);
          }
        
          // remove previous results from BPI_list and imageDetails
          bpiStore.clearBPIList(); 
          bpiList = bpiStore.getBPIChunk(this.currentPage);

          // add the next batch of bpi numbers to pinia BPI_list
          const values = bpiList.toString();
          bpiStore.addToBPIList(values);

          // get the number of items
          const bpi_count = bpiList.length;
          console.log('number of items on new page is ' + bpi_count);     
          console.log('about to get image details for ' + values);
      
          this.getImages(this.currentPage);
        },
        activateImagesModel(id,index){
          this.showImagesModal = true;
          this.bpiNumber = id;
          this.bpiIndex = index;
        },
        closeImagesModal(){
          this.showImagesModal = false;
        }
      }, 
      created() {
        const bpiStore = useBPIStore();
        // get the number of pages
        this.totalPages = bpiStore.getPageNum;
        this.getImages();    
      }
  }
    </script>